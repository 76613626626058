import React from "react";
import styles from "../styles/PostMeta.module.scss";

function PostMeta({ post, className }) {
  const {
    frontmatter: { date },
    fields: {
      readingTime: { text: timeToRead },
    },
  } = post;

  return (
    <div className={[styles.postMeta, className].join(" ")}>
      Posted {date}. {timeToRead && <strong>{timeToRead}.</strong>}
    </div>
  );
}

export default PostMeta;
