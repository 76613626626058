import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostCard from "../components/PostCard";

class Tag extends React.Component {
  render() {
    const {
      data,
      pageContext: { tag },
    } = this.props;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout>
        <SEO title={`Posts tagged “${tag}”`} />

        <h1 className="tag-title text-center space-bottom"># {tag}</h1>

        <div className="posts">
          {posts.map(({ node }) => (
            <PostCard key={node.fields.slug} post={node} />
          ))}
        </div>
      </Layout>
    );
  }
}

export default Tag;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            cover_image {
              childImageSharp {
                fixed(width: 770, height: 380) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
