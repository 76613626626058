import React from "react";
import ToggleTheme from "./ToggleTheme";
import Logo from "./Logo";
import styles from "../styles/Layout.module.scss";

class Layout extends React.Component {
  render() {
    const { showLogo = true } = this.props;

    return (
      <>
        <header className={styles.header}>
          <div className={styles.header__left}>{showLogo && <Logo />}</div>

          <div className={styles.header__right}>
            <ToggleTheme />
          </div>
        </header>

        <main className={styles.main}>{this.props.children}</main>

        <footer className={styles.footer}>
          <span className={styles.footer__links}>
            Powered by <a href="https://www.gatsbyjs.org">Gatsby</a>. Theme
            adapted from{" "}
            <a href="https://github.com/gridsome/gridsome-starter-blog">
              Gridsome starter blog
            </a>
            . Hosted on <a href="https://www.netlify.com/">Netlify</a>.
          </span>
        </footer>
      </>
    );
  }
}

export default Layout;
